import * as yup from 'yup';

// San'yas User::EMAIL_REGEXP
const EMAIL_REGEX = /^[a-z0-9.!#$%&'`*+\-/=^_{}|~]+@((\.)?[a-zA-Z0-9-])+\.[a-zA-Z0-9-]{1,63}$/;

export function isValidEmail(message = 'Invalid email') {
  return this.matches(EMAIL_REGEX, { message, excludeEmptyString: true });
}

yup.addMethod(yup.string, 'isValidEmail', isValidEmail);

export default yup;
