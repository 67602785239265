import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { SIZES } from 'lib/constants';
import { CREATE_FACILITATOR_NOTE, UPDATE_FACILITATOR_NOTE } from 'store/facilitatorDashboard/actions';
import { Button } from 'react-bootstrap';
import SanyasIcon, { ICON_SIZES } from 'components/shared/SanyasIcon';

function Prioritized({ note, assigned, variant, className, size, iconSize, text }) {
  const [localIsStarred, setIsStarred] = useState(note?.isPrioritized);
  const [buttonText, setButtonText] = useState(Array.isArray(text) ? (localIsStarred ? text[1] : text[0]) : text); // eslint-disable-line no-nested-ternary
  const dispatch = useDispatch();

  const updateStar = () => {
    setIsStarred(!localIsStarred);

    if (Array.isArray(text)) {
      setButtonText(localIsStarred ? text[0] : text[1]);
    }

    const ACTION = note?.id ? UPDATE_FACILITATOR_NOTE : CREATE_FACILITATOR_NOTE;
    dispatch(ACTION.request({ ...note, isPrioritized: !localIsStarred }));
  };

  return (
    <Button
      key={note?.id || note?.groupMembershipId}
      variant={variant}
      className={className}
      size={size}
      onClick={updateStar}
    >
      <SanyasIcon
        className={`${text ? 'me-1' : ''} ${(assigned && localIsStarred) ? 'text-accent-yellow' : 'text-accent-deep-grey'}`}
        size={iconSize}
        name={`${(assigned && localIsStarred) ? 'starFill' : 'star'}`}
      />
      {buttonText}
    </Button>
  );
}

Prioritized.defaultProps = {
  note: null,
  assigned: false,
  variant: null,
  className: '',
  size: SIZES.md,
  iconSize: 'md',
  text: null,
};

Prioritized.propTypes = {
  note: PropTypes.shape({
    id: PropTypes.number,
    subGroupId: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    taskId: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    groupMembershipId: PropTypes.number,
    isPrioritized: PropTypes.bool,
  }),
  assigned: PropTypes.bool,
  variant: PropTypes.string,
  className: PropTypes.string,
  size: PropTypes.oneOf(Object.keys(SIZES)),
  iconSize: PropTypes.oneOf(Object.keys(ICON_SIZES)),
  text: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
};

export default Prioritized;
