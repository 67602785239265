import _merge from 'lodash/merge';
import { REDUX_STATUS, TASK_TYPES } from 'lib/constants';
import { addSingleObjectToState } from 'lib/reducerHelpers';
import { CHANGE_CURRENT_FACILITATOR,
  GET_DASHBOARD_FOR_FACILITATOR,
  GET_SECONDARY_DASHBOARD_DATA,
  LOAD_INITIAL_STATE,
  CHANGE_DASHBOARD_SORT_ORDER,
  LOAD_FACILITATOR_DASHBOARD_NOTIFICATIONS,
  LOAD_COMMENTS,
  CREATE_REPLY_COMMENT,
  CREATE_COMMENT,
  UPDATE_COMMENT,
  MARK_NOTIFICATION_AS_READ,
  MARK_NOTIFICATION_AS_UNREAD,
  DELETE_COMMENT,
  CREATE_FACILITATOR_NOTE,
  LOAD_PARTICIPANT,
  LOAD_PARTICIPANT_IWL_STATUS,
  UPDATE_FACILITATOR_NOTE,
  UPDATE_ANSWER_COMMENT,
  CREATE_ANSWER_COMMENT,
  LOAD_JOURNAL_ANSWERS,
  GET_FACILITATOR_LIVE_FEED,
  LOAD_PARTICIPANT_JOURNAL,
  FLAG_JOURNAL,
  GET_SUB_GROUP_DATA,
  RESET_DASHBOARD_STATUS,
} from './actions';

export const SORT_ORDER_TYPES = {
  START_DATE_OLDEST_FIRST: { value: 'start_date_oldest_first', label: 'Start Date (Oldest First)' },
  START_DATE_NEWEST_FIRST: { value: 'start_date_newest_first', label: 'Start Date (Newest First)' },
  CLOSING_DATE_OLDEST_FIRST: { value: 'closing_date_oldest_first', label: 'Closing Date (Oldest First)' },
  CLOSING_DATE_NEWEST_FIRST: { value: 'closing_date_newest_first', label: 'Closing Date (Newest First)' },
  UNREAD_POSTS: { value: 'unread_posts', label: 'Unread Posts' },
  STARRED_TASK: { value: 'is_prioritized', label: 'Starred' },
};

export const CARD_TYPES = {
  PREVIEW_CARD: 'preview',
  COMMENT_CARD: 'comment',
  ANSWER_CARD: 'answer',
};

export const PRIORITY_TYPES = {
  HIGH: 'high',
  MEDIUM: 'medium',
  LOW: 'low',
};

const STORE_LOCATIONS = {
  [CARD_TYPES.PREVIEW_CARD]: 'journalPreviewsById',
  [CARD_TYPES.COMMENT_CARD]: 'commentsById',
  [CARD_TYPES.ANSWER_CARD]: 'participantAnswersById',
};

const PRIORITY_TOTAL_TYPES = {
  [PRIORITY_TYPES.HIGH]: 'highPriorityTotal',
  [PRIORITY_TYPES.MEDIUM]: 'mediumPriorityTotal',
  [PRIORITY_TYPES.LOW]: 'lowPriorityTotal',
};

const initialState = {
  facilitators: {
    byId: {},
    allIds: [],
  },
  currentFacilitator: undefined,
  loadingFacilitatorData: true,
  subGroups: undefined,
  loadingSubGroups: false,
  loadingComments: false,
  loadingJournals: false,
  error: undefined,
  status: REDUX_STATUS.IDLE,
  sortOrder: SORT_ORDER_TYPES.START_DATE_OLDEST_FIRST.value,
  participants: {
    byId: {},
    allIds: [],
  },
  liveFeed: { // for Live Feed
    byId: {},
    allIds: [],
    status: REDUX_STATUS.IDLE,
    error: undefined,
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_INITIAL_STATE.SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case GET_DASHBOARD_FOR_FACILITATOR.SUCCESS:
      return {
        ...state,
        subGroups: action.payload.subGroups,
        loadingFacilitatorData: false,
      };
    case LOAD_FACILITATOR_DASHBOARD_NOTIFICATIONS.REQUEST:
      return {
        ...state,
        loadingSubGroups: true,
      };
    case LOAD_FACILITATOR_DASHBOARD_NOTIFICATIONS.SUCCESS: {
      const facilitator = state.facilitators.byId[action.payload.userId];
      return {
        ...state,
        currentFacilitator: { id: facilitator?.id, firstName: facilitator?.firstName, lastName: facilitator?.lastName },
        subGroups: action.payload.data.notifications.subGroups,
        loadingSubGroups: false,
        error: undefined,
      };
    }

    case GET_SECONDARY_DASHBOARD_DATA.SUCCESS:
      return {
        ...state,
        loadingSubGroups: false,
        subGroups: _merge(
          {},
          state.subGroups,
          action.payload.insufficientParticipations,
          action.payload.extendedParticipants,
          action.payload.flaggedJournals,
        ),
      };
    case CHANGE_CURRENT_FACILITATOR.SYNC: {
      const facilitator = state.facilitators.byId[action.payload.id];

      return {
        ...state,
        currentFacilitator: { id: facilitator.id, firstName: facilitator.firstName, lastName: facilitator.lastName },
        loadingSubGroups: true,
        loadingFacilitatorData: true,
      };
    }
    case CHANGE_DASHBOARD_SORT_ORDER.SYNC:
      return {
        ...state,
        sortOrder: action.payload.sortLabel,
      };

    case LOAD_COMMENTS.REQUEST:
      return {
        ...state,
        loadingComments: true,
        error: undefined,
      };

    case LOAD_COMMENTS.SUCCESS:
      return {
        ...state,
        loadingComments: false,
        commentIds: action.payload.commentIds,
        commentsById: action.payload.commentsById,
        unclearedNotifications: action.payload.unclearedNotifications,
        assignedFacilitatorId: action.payload.assignedFacilitatorId,
        subGroupFacilitatorIds: action.payload.subGroupFacilitatorIds,
      };

    case CREATE_REPLY_COMMENT.SUCCESS:
      return {
        ...state,
        commentsById: {
          ...state.commentsById,
          [action.payload.comment.id]: action.payload.comment,
          [action.payload.comment.commentableId]: {
            ...state.commentsById[action.payload.comment.commentableId],
            replyIds: [...state.commentsById[action.payload.comment.commentableId].replyIds, action.payload.comment.id],
          },
        },
      };

    case UPDATE_COMMENT.SUCCESS: {
      let { subGroups } = state;
      if (action.payload.comment.taskType === TASK_TYPES.POD_DISCUSSION) {
        subGroups = {
          ...state.subGroups,
          [action.payload.comment.subGroupId]: {
            ...state.subGroups[action.payload.comment.subGroupId],
            discussionBoardNotifications: {
              ...state.subGroups[action.payload.comment.subGroupId].discussionBoardNotifications,
              [action.payload.comment.taskId]: {
                ...state.subGroups[action.payload.comment.subGroupId].discussionBoardNotifications[action.payload.comment.taskId],
                flags: action.payload.flags,
              },
            },
          },
        };
      }

      return {
        ...state,
        commentsById: {
          ...state.commentsById,
          [action.payload.comment.id]: {
            ...state.commentsById[action.payload.comment.id],
            ...action.payload.comment,
          },
        },
        subGroups,
        error: undefined,
        status: REDUX_STATUS.SUCCESS,
      };
    }

    case UPDATE_COMMENT.ERROR:
      return {
        ...state,
        error: 'We cannot store your comment, because you are not a member of this pod or you are not assigned to this facilitation point.',
      };

    case CREATE_COMMENT.SUCCESS:
      return {
        ...state,
        commentsById: {
          ...state.commentsById,
          [action.payload.comment.id]: action.payload.comment,
        },
        commentIds: [
          ...state.commentIds, action.payload.comment.id,
        ],
        error: undefined,
      };

    case CREATE_COMMENT.ERROR: // fallthrough, this is handled in the same way as CREATE_REPLY_COMMENT.ERROR
    case CREATE_REPLY_COMMENT.ERROR:
      return {
        ...state,
        error: 'We cannot store your comment, because you are not a member of this pod or you are not assigned to this facilitation point.',
      };

    case LOAD_JOURNAL_ANSWERS.REQUEST:
      return {
        ...state,
        loadingAnswers: true,
        error: undefined,
      };

    case LOAD_JOURNAL_ANSWERS.SUCCESS:
      return {
        ...state,
        loadingAnswers: false,
        journalPreviewIds: action.payload.answerIds,
        journalPreviewsById: action.payload.answersById,
        assignedFacilitatorId: action.payload.assignedFacilitatorId,
        subGroupFacilitatorIds: action.payload.subGroupFacilitatorIds,
      };

    case CREATE_ANSWER_COMMENT.SUCCESS:
      return {
        ...state,
        journalPreviewsById: {
          ...state.journalPreviewsById,
          [action.payload.comment.id]: action.payload.comment,
          [action.payload.comment.commentableId]: {
            ...state.journalPreviewsById[action.payload.comment.commentableId],
            replyIds: [...state.journalPreviewsById[action.payload.comment.commentableId].replyIds, action.payload.comment.id],
          },
        },
      };
    case CREATE_ANSWER_COMMENT.ERROR:
      return {
        ...state,
        error: 'We cannot store your comment, because you are not a member of this pod or you are not assigned to this facilitation point.',
      };

    case UPDATE_ANSWER_COMMENT.SUCCESS:
      return {
        ...state,
        journalPreviewsById: {
          ...state.journalPreviewsById,
          [action.payload.comment.id]: {
            ...state.journalPreviewsById[action.payload.comment.id],
            ...action.payload.comment,
          },
        },
        subGroups: {
          ...state.subGroups,
          [action.payload.comment.subGroupId]: {
            ...state.subGroups[action.payload.comment.subGroupId],
            journalNotifications: {
              ...state.subGroups[action.payload.comment.subGroupId].discussionBoardNotifications,
              [action.payload.comment.taskId]: {
                ...state.subGroups[action.payload.comment.subGroupId].discussionBoardNotifications[action.payload.comment.taskId],
                flags: action.payload.flags,
              },
            },
          },
        },
        error: undefined,
      };
    case UPDATE_ANSWER_COMMENT.ERROR:
      return {
        ...state,
        error: 'We cannot store your comment, because you are not a member of this pod or you are not assigned to this facilitation point.',
      };

    case MARK_NOTIFICATION_AS_READ.SUCCESS: {
      const { subGroupId, taskId, taskType, triggerId, triggerType } = action.payload;
      const notificationStore = taskType === TASK_TYPES.JOURNAL ? 'journalNotifications' : 'discussionBoardNotifications';
      const storeLocation = STORE_LOCATIONS[action.meta.cardType];
      let updatePriorityTotal;
      const notificationFor = (action.meta.cardType === CARD_TYPES.ANSWER_CARD || (action.meta.cardType === CARD_TYPES.PREVIEW_CARD && triggerType === 'Answer')) ? 'primary' : 'replies';
      const priorityLevel = PRIORITY_TOTAL_TYPES[action.meta.priority];

      if (taskType === TASK_TYPES.JOURNAL) {
        const gmIds = [...state.subGroups[subGroupId][notificationStore][taskId].triggerGmIds];
        gmIds.splice(gmIds.indexOf(action.meta.gmId), 1);
        updatePriorityTotal = {
          triggerGmIds: gmIds,
          [notificationFor]: {
            ...state.subGroups[subGroupId][notificationStore][taskId][notificationFor],
            [priorityLevel]: state.subGroups[subGroupId][notificationStore][taskId][notificationFor][priorityLevel] - 1,
          },
        };
      } else {
        updatePriorityTotal = { [priorityLevel]: state.subGroups[subGroupId][notificationStore][taskId][priorityLevel] - 1 };
      }

      return {
        ...state,
        subGroups: {
          ...state.subGroups,
          [subGroupId]: {
            ...state.subGroups[subGroupId],
            [notificationStore]: {
              ...state.subGroups[subGroupId][notificationStore],
              [taskId]: {
                ...state.subGroups[subGroupId][notificationStore][taskId],
                ...updatePriorityTotal,
                notificationTotal: state.subGroups[subGroupId][notificationStore][taskId].notificationTotal - 1,
              },
            },
          },
        },
        [storeLocation]: {
          ...state[storeLocation],
          [triggerId]: {
            ...state[storeLocation][triggerId],
            notificationCleared: true,
          },
        },
      };
    }

    case MARK_NOTIFICATION_AS_UNREAD.SUCCESS: {
      const { subGroupId, taskId, taskType, triggerId, triggerType } = action.payload;
      const notificationStore = taskType === TASK_TYPES.JOURNAL ? 'journalNotifications' : 'discussionBoardNotifications';
      const storeLocation = STORE_LOCATIONS[action.meta.cardType];
      const notificationFor = (action.meta.cardType === CARD_TYPES.ANSWER_CARD || (action.meta.cardType === CARD_TYPES.PREVIEW_CARD && triggerType === 'Answer')) ? 'primary' : 'replies';
      const priorityLevel = PRIORITY_TOTAL_TYPES[action.meta.priority];

      let updatePriorityTotal;

      if (taskType === TASK_TYPES.JOURNAL) {
        updatePriorityTotal = {
          triggerGmIds: [...state.subGroups[subGroupId][notificationStore][taskId].triggerGmIds, action.meta.gmId],
          [notificationFor]: {
            ...state.subGroups[subGroupId][notificationStore][taskId][notificationFor],
            [priorityLevel]: state.subGroups[subGroupId][notificationStore][taskId][notificationFor][priorityLevel] + 1,
          },
        };
      } else {
        updatePriorityTotal = { [priorityLevel]: (state.subGroups[subGroupId][notificationStore][taskId][priorityLevel] + 1) };
      }

      return {
        ...state,
        subGroups: {
          ...state.subGroups,
          [subGroupId]: {
            ...state.subGroups[subGroupId],
            [notificationStore]: {
              ...state.subGroups[subGroupId][notificationStore],
              [taskId]: {
                ...state.subGroups[subGroupId][notificationStore][taskId],
                ...updatePriorityTotal,
                notificationTotal: state.subGroups[subGroupId][notificationStore][taskId].notificationTotal + 1,
              },
            },
          },
        },
        [storeLocation]: {
          ...state[storeLocation],
          [triggerId]: {
            ...state[storeLocation][triggerId],
            notificationCleared: false,
          },
        },
      };
    }

    case LOAD_PARTICIPANT.SUCCESS: {
      if (action.meta.cached) return state;

      return {
        ...state,
        participants: addSingleObjectToState(state.participants, action.payload),
      };
    }
    case LOAD_PARTICIPANT.ERROR: {
      const { name, message } = action.payload;

      return {
        ...state,
        participants: addSingleObjectToState(state.participants, { ...action.meta, error: { name, message } }),
      };
    }

    case LOAD_PARTICIPANT_IWL_STATUS.SUCCESS: {
      return {
        ...state,
        participants: {
          ...state.participants,
          byId: {
            ...state.participants.byId,
            [action.payload.groupMembershipId]: {
              ...state.participants.byId[action.payload.groupMembershipId],
              iwlStatus: action.payload,
            },
          },
        },
      };
    }

    case DELETE_COMMENT.SUCCESS: {
      const { [action.payload]: removed, ...remainingCommentsById } = state.commentsById;
      const index = state.commentIds.indexOf(action.payload);
      const updatedCommentIds = [...state.commentIds];
      updatedCommentIds.splice(index, 1);
      return {
        ...state,
        commentsById: remainingCommentsById,
        commentIds: updatedCommentIds,
        error: undefined,
      };
    }

    case DELETE_COMMENT.ERROR:
      return {
        ...state,
        error: 'We cannot store your comment, because you are not a member of this pod or you are not assigned to this facilitation point.',
      };

    case CREATE_FACILITATOR_NOTE.SUCCESS: // fallthrough, this is handled in the same way as UPDATE_FACILITATOR_NOTE.SUCCESS
    case UPDATE_FACILITATOR_NOTE.SUCCESS: {
      if (action.payload.userTaskId) {
        const journalPreview = state.journalPreviewsById && Object.values(state.journalPreviewsById).find((preview) => preview.userTaskId === action.payload.userTaskId);
        const userTasks = { ...state.userTasks };
        userTasks[action.payload.userTaskId] = userTasks[action.payload.userTaskId] ? { ...userTasks[action.payload.userTaskId], note: action.payload } : { note: action.payload };

        return {
          ...state,
          journalPreviewsById: {
            ...state.journalPreviewsById,
            [journalPreview.id]: {
              ...journalPreview,
              note: action.payload,
            },
          },
          userTasks,
        };
      }

      const notificationStore = action.payload.taskType === TASK_TYPES.JOURNAL ? 'journalNotifications' : 'discussionBoardNotifications';
      return {
        ...state,
        subGroups: {
          ...state.subGroups,
          [action.payload.subGroupId]: {
            ...state.subGroups[action.payload.subGroupId],
            [notificationStore]: {
              ...state.subGroups[action.payload.subGroupId][notificationStore],
              [action.payload.taskId]: {
                ...state.subGroups[action.payload.subGroupId][notificationStore][action.payload.taskId],
                note: action.payload,
              },
            },
          },
        },
      };
    }

    case GET_FACILITATOR_LIVE_FEED.REQUEST:
      return {
        ...state,
        liveFeed: {
          ...state.liveFeed,
          status: REDUX_STATUS.PENDING,
        },
      };
    case GET_FACILITATOR_LIVE_FEED.SUCCESS:
      return {
        ...state,
        liveFeed: {
          byId: action.payload.notifications,
          allIds: action.payload.notificationIds,
          status: REDUX_STATUS.SUCCESS,
        },
      };
    case GET_FACILITATOR_LIVE_FEED.ERROR:
      return {
        ...state,
        liveFeed: {
          ...state.liveFeed,
          status: REDUX_STATUS.ERROR,
          error: action.payload,
        },
      };

    case LOAD_PARTICIPANT_JOURNAL.SUCCESS:
      return {
        ...state,
        loadingJournals: false,
        participantAnswerIds: action.payload.answerIds,
        participantAnswersById: action.payload.answersById,
        commentIds: action.payload.commentIds,
        commentsById: action.payload.commentsById,
        questionIds: action.payload.questionIds,
        questionsById: action.payload.questionsById,
        userTasks: {
          ...state.userTasks,
          [action.payload.userTask.userTaskId]: action.payload.userTask,
        },
      };

    case LOAD_PARTICIPANT_JOURNAL.ERROR:
      return {
        ...state,
        error: 'We cannot load journals because you are not a member of this pod or you are not assigned to this facilitation point',
      };

    case FLAG_JOURNAL.SUCCESS: {
      const journalPreview = state.journalPreviewsById && Object.values(state.journalPreviewsById).find((preview) => preview.userTaskId === action.payload.userTaskId);
      const flaggedJournals = state.subGroups[action.payload.subGroupId].flaggedJournals || [];
      const updatedFlaggedJournals = [...flaggedJournals];
      if (action.payload.isFlagged) {
        updatedFlaggedJournals.push({ groupMembershipId: Number(action.payload.gmId), taskId: Number(action.payload.taskId), fullName: journalPreview.fullName });
      } else {
        const index = state.subGroups[action.payload.subGroupId]?.flaggedJournals?.findIndex((flaggedJournal) => flaggedJournal.groupMembershipId === Number(action.payload.gmId) && flaggedJournal.taskId === Number(action.payload.taskId));
        if (index && index > -1) {
          updatedFlaggedJournals.splice(index, 1);
        }
      }

      return {
        ...state,
        journalPreviewsById: {
          ...state.journalPreviewsById,
          [journalPreview.id]: {
            ...state.journalPreviewsById[journalPreview.id],
            flagged: action.payload.isFlagged,
            flag: action.payload.flag,
          },
        },
        subGroups: {
          ...state.subGroups,
          [action.payload.subGroupId]: {
            ...state.subGroups[action.payload.subGroupId],
            flaggedJournals: updatedFlaggedJournals,
          },
        },
      };
    }

    case GET_SUB_GROUP_DATA.SUCCESS:
      return {
        ...state,
        subGroups: {
          ...state.subGroups,
          [action.payload.subGroupId]: action.payload,
        },
      };

    case RESET_DASHBOARD_STATUS.SYNC:
      return {
        ...state,
        status: REDUX_STATUS.IDLE,
      };

    default:
      return state;
  }
};
