import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { LEGACY_APP_DOMAIN } from 'config';
import { GroupPropType } from 'lib/propTypes';
import { buildRoutePath } from 'lib/routerHelpers';
import { REGISTRAR_GROUP_PROGRESS_REPORT_PATH } from 'lib/routerPaths';
import ReportLinkCard from 'components/groups/ReportLinkCard';
import ProgressTabPage from './Reports/ProgressTabPage';

function GroupReportsTabPage({ group }) {
  if (!group) {
    return null;
  }

  return (
    <Switch>
      <Route path={REGISTRAR_GROUP_PROGRESS_REPORT_PATH}>
        <ProgressTabPage />
      </Route>
      <Route path="*">
        <div className="row row-cols-1 row-cols-md-5 g-3">
          {group.reports.dashboard && (
          <div className="col">
            <ReportLinkCard
              title="Dashboard"
              description="A bird&rsquo;s eye view of where participants are at."
              link={`${buildRoutePath(REGISTRAR_GROUP_PROGRESS_REPORT_PATH, { id: group.id })}`}
            />
          </div>
          )}

          {group.reports.ptq && (
          <div className="col">
            <ReportLinkCard
              title="PTQ"
              description="At-a-glance report to see who is done the pre and post test questionnaires."
              link={`${LEGACY_APP_DOMAIN}/reports/groups/${group.id}/ptq`}
            />
          </div>
          )}

          <div className="col">
            <ReportLinkCard
              title="% Complete"
              description="A very simple report that shows percentage complete of each participant."
              link={`${LEGACY_APP_DOMAIN}/reports/groups/${group.id}/percentage`}
            />
          </div>

          {group.reports.quiz && (
          <div className="col">
            <ReportLinkCard
              title="Quiz Analyzer"
              description="Get an idea of how many people are getting multiple choice questions right or wrong."
              link={`${LEGACY_APP_DOMAIN}/reports/groups/${group.id}/quiz_analyzer`}
            />
          </div>
          )}

          {group.reports.survey && (
          <div className="col">
            <ReportLinkCard
              title="Survey Analyzer"
              description="See how participants across all groups are responding to survey questionnaires such as pre/post tests."
              link={`${LEGACY_APP_DOMAIN}/reports/groups/${group.id}/survey_analyzer`}
            />
          </div>
          )}
        </div>
      </Route>
    </Switch>
  );
}

GroupReportsTabPage.defaultProps = {
  group: null,
};

GroupReportsTabPage.propTypes = {
  group: GroupPropType,
};

export default GroupReportsTabPage;
